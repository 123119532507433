import React from 'react'
import tw from 'twin.macro'
import {
  Header,
  CardsSection,
  CasesSection,
  CirclesSection,
  JobsSection,
  TeamSection,
} from '@modules'
import { Spacer } from '@elements'
import { useStateContext } from '@context/stateContext'

const IndexPage = () => {
  const {
    content: {
      pages: {
        home: { sections },
      },
    },
  } = useStateContext()

  return (
    <Container>
      <Spacer className="pt-10 2xl:pt-16 bg-gray" />
      <Header data={sections.header} />
      <Spacer className="pb-10 md:pb-20 lg:pb-40 xl:pb-56 bg-gray" />
      <CirclesSection data={sections.circles} />
      <Spacer className="pb-24 xs:pb-32 sm:pb-40 md:pb-48 lg:pb-56 xl:pb-64 2xl:pb-96" />
      <CasesSection data={sections.cases} />
      <Spacer className="pb-10 md:pb-20 lg:pb-8 xl:pb-16 2xl:pb-20 fhd:pb-24" />
      <CardsSection data={sections.cards} />
      <Spacer className="pb-10 md:pb-20 lg:pb-40 xl:pb-60" />
      <TeamSection data={sections.team} />
      <Spacer className="pb-10 md:pb-20 lg:pb-40 xl:pb-48" />
      <JobsSection data={sections.jobs} />
    </Container>
  )
}

export default IndexPage

const Container = tw.div`w-full bg-background overflow-x-hidden`
